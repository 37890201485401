<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        class="break-word"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle
        class="break-word"
      >
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        <v-chip
          color="info"
          dark
          small
          label
          class="mr-1"
        >
          {{ formattedItem.unidades }} {{ 'unidad'|pluralize(parseFloat(formattedItem.unidades), 'es') }}
        </v-chip>
        <v-chip
          v-if="formattedItem.lparte_trabajo.facturar"
          color="green"
          dark
          small
          label
          class="mr-1"
        >
          Facturable
        </v-chip>
        <v-chip
          v-else
          color="red"
          dark
          small
          label
          class="mr-1"
        >
          No facturable
        </v-chip>
        <v-chip
          v-if="formattedItem.lparte_trabajo.idaccion_ejec"
          color="info"
          dark
          small
          label
          class="mr-1"
        >
          Acción {{ formattedItem.maccion.descripcion_corta }}
        </v-chip>
        <v-chip
          v-if="formattedItem.faltaNserie"
          color="error"
          dark
          small
          label
          class="mr-1"
        >
          Nº de serie
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="!readonly && !formattedItem.lparte_trabajo.idaccion_ejec"
    >
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import filters from '@/utils/filters'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.subtitle = this.$offline.lparteTrabajo.resumenFichaTecnica(item).join(' - ')
      item.title = item.lparte_trabajo.descripcion
      item.unidades = filters.decimal(item.lparte_trabajo.unidades)
      item.faltaNserie = (!item.lparte_trabajo.nserie && item.articulo?.nserie_obligado)
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    }
  },
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
